import { Image, Button } from "./index";
import { BlackLogo, NotificationIcon, Clock } from "../../assets/images/userDashboard";
import useToggle from "../../hooks/useToggle";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { User } from "../../types/authProvider";
import { useSocket } from "../../context/SocketContext";
import { useEffect, useState } from "react";
import { deleteNotification, getNotificationCount, listNotifications } from "../../service/Header";
import { formatTimeAgo } from "../../utils/NotitificationTimeFormat";
import { getInitials } from "../../utils/getInitials";
import useClickOutside from "../../hooks/useClickOutside"


interface Notification {
    _id: string;
    sender: {
        _id: string;
        username: string;
    };
    receiver: string;
    message: string;
    type: number;
    data: {
        _id: string;
        duration: number;
        expiration: string;
        global_score: string;
        candidate_id: string;
        job_id: string;
        recommendation: string;
        recommendation_color: string;
        interview_status: string;
        video_url: string;
        analytics: string;
        questions_list: any[];
        analytics_data: any[];
        created_at: string;
        updated_at: string;
        interview_end: string;
        interview_slot: string;
    };
    sender_model: string;
    receiver_model: string;
    status: number;
    created_at: string;
    updated_at: string;
}

const Header = () => {
    const [toggle, setToggle] = useToggle(false);
    const [notificationToggle, setNotificationToggle] = useToggle(false);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [unreadCount, setUnreadCount] = useState<number | null>();
    const { logout } = useAuthContext();
    const { socket } = useSocket();
    const navigate = useNavigate();


    let initials
    const user: User | null = JSON.parse(localStorage.getItem("admin") || "null");
    if (user) initials = user ? `${user.first_name?.[0]?.toUpperCase() || ''}${user.last_name?.[0]?.toUpperCase() || ''}` : '';

    useEffect(() => {
        if (socket) {
            socket.on('notification', async (data) => {

                if (data) {
                    const firstNotification = data.results[0]; // Get the first notification

                    if (firstNotification) {
                        const processedNotification = {
                            _id: firstNotification._id,
                            sender: firstNotification.sender,
                            receiver: firstNotification.receiver,
                            message: firstNotification.message,
                            type: firstNotification.type,
                            data: firstNotification.data,
                            sender_model: firstNotification.sender_model,
                            receiver_model: firstNotification.receiver_model,
                            status: firstNotification.status,
                            created_at: new Date(firstNotification.created_at).toISOString(),
                            updated_at: new Date(firstNotification.updated_at).toISOString(),
                        };

                        // Use the processedNotification as needed
                        setNotifications(prevNotifications => [
                            processedNotification,
                            ...prevNotifications,
                        ]);
                    }

                    setUnreadCount(data.unreadNotificationCount);
                }
            });
            return () => {
                socket.off('notification');
            };
        }
    }, [socket]);

    const fetchNotifications = async () => {
        try {
            if (!user) return
            const result = await listNotifications(user?._id);
            if (result.length>0) {
                setNotifications(result.map((notification: Notification) => ({
                    ...notification,
                    created_at: new Date(notification.created_at).toISOString(),
                    updated_at: new Date(notification.updated_at).toISOString()
                })));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchNotificationCount = async () => {
        try {
            if (!user) return
            const result = await getNotificationCount(user?._id);
            if (result) {
                setUnreadCount(result.count)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteNotification = async () => {
        try {
            if (!user) return
            const result = await deleteNotification(user?._id);
            if (result) {
                setNotifications([])
                fetchNotifications();
                setUnreadCount(null)
            }
        } catch (error) {
            console.log(error);
        }
    }


    const toggleNotification = () => {
        fetchNotifications()
        setUnreadCount(null);
        setNotificationToggle();
    }

    const handleLogout = () => {
        logout();
        navigate("/login")
    }

    const notificationRef = useClickOutside(() => {
        if (notificationToggle) setNotificationToggle(false);
    });


    useEffect(() => {
        fetchNotificationCount()
    }, [user?._id])

    return (
        <header className="header-section">
            <div className="d-flex align-items-center justify-content-between">
                <Link to='/dashboard' className="d-block">
                    <picture>
                        <Image src={BlackLogo} alt="black logo" className="img-fluid" />
                    </picture>
                </Link>
                <div className="d-flex align-items-center gap-4">

                    <div className="position-relative notification-icon" ref={notificationRef}>
                        <button className="border-0 bg-transparent p-0 me-2" onClick={toggleNotification}>
                            <Image src={NotificationIcon} alt="notification" className="img-fluid" /></button>

                        {typeof unreadCount === 'number' && unreadCount > 0 && (
                            <span className="notification-badge">{unreadCount}</span>
                        )}

                        {notificationToggle && (
                            <div className="position-absolute notification">
                                <div className="notification-head d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 fw-semibold">Notification</h4>
                                    {notifications.length > 0 && (
                                        <button className="border-0 bg-transparent fw-semibold text-decoration-underline p-0" onClick={handleDeleteNotification}>Clear All</button>
                                    )}
                                </div>
                                <div className="notification-details">
                                    {notifications && notifications.length > 0 ? (
                                        notifications.map((notification: any, index: number) => {
                                            // Ensure sender is a string and has a username property
                                            const sender = notification.sender && notification.sender.username ? notification.sender.username : 'Unknown Sender';
                                            // Ensure jobId is a string
                                            const jobId = notification.data && notification.data.job_id ? notification.data.job_id.name : 'Unknown Job';
                                            // Ensure interviewSlot is a valid Date object
                                            const interviewSlot = notification.data && notification.data.interview_slot && new Date(notification.data.interview_slot);
                                            const expiration = notification.data?.expiration && new Date(notification.data.expiration);

                                            // Use expiration if type is 6, otherwise use interview_end
                                            let interviewEnd = notification.type === 6 ? expiration : (notification.data?.interview_end ? new Date(notification.data.interview_end) : null);

                                            // Add 5 hours and 30 minutes to the expiration time if type is 6
                                            if (notification.type === 6 && expiration) {
                                                expiration.setHours(expiration.getHours() + 5);
                                                expiration.setMinutes(expiration.getMinutes() + 30);
                                                interviewEnd = expiration; // Update interviewEnd to the adjusted expiration
                                            }

                                            // Ensure interviewSlot is valid before adjusting
                                            const adjustedInterviewSlot = interviewSlot && new Date(interviewSlot.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000));
                                            const adjustedInterviewEnd = interviewEnd && new Date(interviewEnd.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000));

                                            // Ensure createdAt is valid
                                            const createdAt = notification.created_at && new Date(notification.created_at);

                                            // Format time and date only if they are valid
                                            const timeOptions: any = { hour: 'numeric', minute: 'numeric', hour12: true };
                                            const dateOptions: any = { day: '2-digit', month: 'short', year: 'numeric' };

                                            const formattedTime = adjustedInterviewSlot ? adjustedInterviewSlot.toLocaleTimeString('en-IN', timeOptions) : null;
                                            const formattedDate = adjustedInterviewSlot ? adjustedInterviewSlot.toLocaleDateString('en-IN', dateOptions) : null;

                                            const formattedEndTime = adjustedInterviewEnd ? adjustedInterviewEnd.toLocaleTimeString('en-IN', timeOptions) : null;
                                            const formattedEndDate = adjustedInterviewEnd ? adjustedInterviewEnd.toLocaleDateString('en-IN', dateOptions) : null;

                                            const timeAgo = createdAt ? formatTimeAgo(createdAt) : null;

                                            let notificationMessage = '';
                                            switch (notification.message) {
                                                case 'Interview has been completed':
                                                    notificationMessage = `Interview for ${jobId} has been completed at ${formattedEndTime} on ${formattedDate}`;
                                                    break;
                                                case 'Has missed the interview':
                                                    notificationMessage = `has missed the interview for ${jobId}. It was expired at ${formattedEndTime} on ${formattedEndDate}`;
                                                    break;
                                                case 'Interview has been scheduled':
                                                    notificationMessage = `Interview for ${jobId} has been scheduled at ${formattedTime} on ${formattedDate}`;
                                                    break;
                                                default:
                                                    notificationMessage = 'Unknown notification type';
                                            }

                                            return (
                                                <div key={notification._id} className={`notification-main d-flex align-items-center ${index % 2 === 0 ? 'active' : ''}`} onClick={() => navigate(`/interview?interviewId=${notification?.data._id}`)}>
                                                    <div className="title-initials fw-semibold">
                                                        <span>{getInitials(sender)}</span>
                                                    </div>
                                                    <div className="user-details">
                                                        <p className="fw-normal">
                                                            <span>{sender}</span> {notificationMessage}
                                                        </p>
                                                        <span className="notification-hrs fw-normal d-inline-flex">
                                                            <img src={Clock} alt="clock" className="img-fluid" />
                                                            {timeAgo || 'No time available'}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="notification-main d-flex align-items-center justify-content-center"><p className="mb-0">No notifications available</p></div>
                                    )}



                                </div>
                            </div>
                        )}
                    </div>


                    <div className="dropdown">
                        <Button className="btn btn-secondary fw-semibold border-0" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false" onClick={() => setToggle()}>{initials}</Button>
                        {toggle && <ul className="dropdown-menu show p-0">
                            <li className="dropdown-item" onClick={handleLogout}>Logout</li>
                        </ul>}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header